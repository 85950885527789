import { HttpHandlerFn, HttpInterceptorFn, HttpRequest } from '@angular/common/http';
import { inject } from '@angular/core';
import { ProcessingHttpInterceptorStateService } from './processing-http-interceptor-state.service';
import { finalize } from 'rxjs';

export const ProcessingHttpInterceptorInterceptor: HttpInterceptorFn = (req: HttpRequest<unknown>, next: HttpHandlerFn) => {
  const state = inject(ProcessingHttpInterceptorStateService);
  state.add(req);
  return next(req).pipe(finalize(() => state.remove(req)));
};
