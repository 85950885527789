import { APP_INITIALIZER, ApplicationConfig, ErrorHandler, importProvidersFrom, inject, LOCALE_ID } from '@angular/core';
import { ROUTES, RouteReuseStrategy, provideRouter, withInMemoryScrolling } from '@angular/router';
import { routes } from './app.routes';
import { FIREBASE_TOKEN } from './injection-tokens';
import "firebase/compat/auth";
import compatApp from "firebase/compat/app";
import { UserContextService } from './services/user-context.service';
import { NavigationService } from './services/navigation.service';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { provideNoopAnimations } from '@angular/platform-browser/animations';
import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { AuthHttpInterceptorFn } from './services/auth-http-interceptor.service';
import { ApiModule, Configuration } from './openapi';
import { environment } from '../environments/environment';
import { ProcessingHttpInterceptorInterceptor } from './services/processing-http-interceptor.interceptor';
import { CustomRouteReuseStrategy } from './services/custom-route-reuse-strategy.service';
import { provideToastr } from 'ngx-toastr';
import { ErrorHandlerService } from './services/error-handler.service';
import { ProcessingHttpInterceptorStateService } from './services/processing-http-interceptor-state.service';
import { noop } from 'lodash-es';
import { provideServiceWorker } from '@angular/service-worker';
import { availableLocales } from './services/helper-functions';
import { LocaleManagerService } from './services/locale-manager.service';


compatApp.initializeApp(environment.firebaseOptions);
const firebaseAuth = compatApp.auth();
firebaseAuth.setPersistence(compatApp.auth.Auth.Persistence.LOCAL);

export const appConfig: ApplicationConfig = {
  providers: [
    { provide: FIREBASE_TOKEN, useValue: firebaseAuth },
    provideNoopAnimations(),
    provideHttpClient(withInterceptors([ProcessingHttpInterceptorInterceptor, AuthHttpInterceptorFn])),
    {
      provide: ROUTES,
      useFactory: () => routes(inject(ProcessingHttpInterceptorStateService)),
      deps: [ProcessingHttpInterceptorStateService],
      multi: true,
    },
    provideRouter([], withInMemoryScrolling({ scrollPositionRestoration: 'top' })),
    { provide: LOCALE_ID, useValue: availableLocales[LocaleManagerService.getLocale()] },
    {
      provide: APP_INITIALIZER,
      useFactory: () => {
        // force initialization on app start
        inject(UserContextService);
        inject(NavigationService);
        return noop;
      },
      multi: true,
    },
    importProvidersFrom(
      CalendarModule.forRoot({
        provide: DateAdapter,
        useFactory: adapterFactory,
      })
    ),
    importProvidersFrom(
      ApiModule.forRoot(() => new Configuration({
        basePath: environment.apiUrl,
        withCredentials: true
      }))
    ),
    provideToastr({
      timeOut: 5000,
      positionClass: 'toast-bottom-right',
      preventDuplicates: true,
    }),
    { provide: RouteReuseStrategy, useClass: CustomRouteReuseStrategy },
    { provide: ErrorHandler, useClass: ErrorHandlerService },
    provideServiceWorker('ngsw-worker.js', {
      enabled: true,
      registrationStrategy: 'registerWhenStable:30000'
    }),
  ]
};
