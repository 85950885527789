import { HttpHandlerFn, HttpInterceptorFn, HttpRequest } from '@angular/common/http';
import { inject } from '@angular/core';
import { from, switchMap } from 'rxjs';
import { UserContextService } from './user-context.service';

export const AuthHttpInterceptorFn: HttpInterceptorFn = (req: HttpRequest<unknown>, next: HttpHandlerFn) => {
  const userContext = inject(UserContextService);
  return from(userContext.getToken()).pipe(switchMap(token => {
    const authReq = req.clone({
      headers: req.headers.set('Authorization', `Bearer ${token}`)

    });
    return next(authReq);
  }));
};
