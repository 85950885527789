import { bootstrapApplication } from '@angular/platform-browser';
import { appConfig } from './app/app.config';
import { AppComponent } from './app/app.component';
import { loadTranslations } from '@angular/localize';
import { registerLocaleData } from '@angular/common';
import { LocaleManagerService } from './app/services/locale-manager.service';
import { AvailableLocales } from './app/openapi';
import { availableLocales } from './app/services/helper-functions';

initLanguage(LocaleManagerService.getLocale())
  .then(() => import("./app/app.component"))
  .then(
    () => bootstrapApplication(AppComponent, appConfig)
      .catch((err) => console.error(err))
  );

interface IAnyModule {
  default: unknown;
};

async function initLanguage(locale: AvailableLocales): Promise<void> {
  if (locale === AvailableLocales.US) {
    // Default behavior, no changes required
    return;
  }

  const localeString = availableLocales[locale];
  // Fetch translation file
  const json = await fetch(`assets/locale/${localeString}.json`)
    .then((r) => r.text())
    .then((t) => JSON.parse(t));

  // Initialize translation
  loadTranslations(json);
  $localize.locale = localeString;

  // // Load required locale module (needs to be adjusted for different locales)
  const localesModules: Record<AvailableLocales, () => Promise<IAnyModule>> = {
    [AvailableLocales.PT]: () => import(`../node_modules/@angular/common/locales/pt-PT`),
    [AvailableLocales.US]: null!, // default
  }
  const localeModule = await localesModules[locale]();
  registerLocaleData(localeModule.default);
}
