import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, Injectable, Injector, NgZone } from '@angular/core';
import { isObject, isString } from 'lodash-es';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class ErrorHandlerService implements ErrorHandler {
  static toastr: ToastrService;
  constructor(
    private injector: Injector,
    private zone: NgZone,
  ) { }

  handleError(error: unknown): void {
    const toastr = this.injector.get(ToastrService);
    let message: string;
    if (error instanceof Error) {
      message = error.message
    } else if (error instanceof HttpErrorResponse) {
      message = isString(error.error)
        ? error.error
        : this.extractMessage(error.error);
    } else if (isObject(error) && 'code' in error && 'reason' in error) {
      if (error.code === 1006 && !error.reason) {
        message = $localize`Live events failed, attempting to reconnect...`;
        this.zone.run(() => toastr.warning(message, undefined, { timeOut: 1000 }));
        console.warn(error);
        return;
      }
    } else {
      message = JSON.stringify(error);
    }
    this.zone.run(() => toastr.error(message, undefined, { disableTimeOut: true }));
    console.error(error);
  }

  extractMessage(error: unknown): string {
    if (isObject(error) && 'title' in error) {
      return error.title as string;
    }
    return $localize`Unexpected error.`;
  }
}
