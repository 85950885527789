import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, DetachedRouteHandle, RouteReuseStrategy, UrlSegment } from '@angular/router';
import { flatten } from 'lodash-es';

@Injectable()
export class CustomRouteReuseStrategy extends RouteReuseStrategy {
  storedHandles: Record<string, DetachedRouteHandle> = {};

  override shouldDetach(route: ActivatedRouteSnapshot): boolean {
    return !!route.data.reuseRoute;
  }

  override store(route: ActivatedRouteSnapshot, handle: DetachedRouteHandle): void {
    const id = this.createIdentifier(route);
    if (route.data.reuseRoute) {
      this.storedHandles[id] = handle;
    }
  }

  override shouldAttach(route: ActivatedRouteSnapshot): boolean {
    const id = this.createIdentifier(route);
    return !!route.routeConfig && !!this.storedHandles[id];
  }

  override retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle | null {
    const id = this.createIdentifier(route);
    return !route.routeConfig || !this.storedHandles[id]
      ? null
      : this.storedHandles[id];
  }

  override shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
    return future.routeConfig === curr.routeConfig;
  }

  private createIdentifier(route: ActivatedRouteSnapshot) {
    const segments: UrlSegment[][] = route.pathFromRoot.map(r => r.url);
    const subpaths = flatten(segments).map(segment => segment.path);
    return segments.length + '-' + subpaths.join('/');
  }
}
