import { Component } from '@angular/core';
import { IActionable } from '../models/IActionable';
import { ModalService } from '../services/modal.service';
import { wait } from '../services/helper-functions';
import { ActionableComponent } from '../actionables/actionable/actionable.component';

@Component({
  selector: 'bk-no-permissions-modal',
  standalone: true,
  imports: [
    ActionableComponent,
  ],
  templateUrl: './no-permissions-modal.component.html',
  styleUrl: './no-permissions-modal.component.scss'
})
export class NoPermissionsModalComponent {
  actionable: IActionable = {
    label: $localize`Close`,
    handler: () => this.modalService.toggleModal(),
    executing$: wait,
    customizations: 'secondary',
    requiredEntitlements: [],
  };

  constructor(
    private modalService: ModalService,
  ) { }
}
